import { coreApi, priceTypeReducer, userApi, userReducer } from '@autone/ui';
import { configureStore } from '@reduxjs/toolkit';
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';

import { rebalancingApi } from './services';
import { type AppDispatch } from './store.types';

export const store = configureStore({
  reducer: {
    [rebalancingApi.reducerPath]: rebalancingApi.reducer,
    [coreApi.reducerPath]: coreApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    user: userReducer,
    priceType: priceTypeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rebalancingApi.middleware)
      .concat(coreApi.middleware)
      .concat(userApi.middleware),
});

type RootState = ReturnType<typeof store.getState>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
// eslint-disable-next-line
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
