const START_TIME = { hours: 0, minutes: 0 };
const END_TIME = { hours: 23, minutes: 45 };

export const getTimes = () => {
  const times = [];
  for (let i = START_TIME.hours; i <= END_TIME.hours; i++) {
    const startMinutes = i === START_TIME.hours ? START_TIME.minutes : 0;
    const endMinutes = i === END_TIME.hours ? END_TIME.minutes : 59;
    for (let j = startMinutes; j <= endMinutes; j += 15) {
      const hours = i.toString().padStart(2, '0');
      const minutes = j.toString().padStart(2, '0');
      times.push({ id: `${hours}:${minutes}`, title: `${hours}:${minutes}` });
    }
  }
  return times;
};
