import {
  type LocationReceivingSkuProposal,
  type LocationSendingSkuProposal,
} from '@autone/openapi-rebalancing';
import { isArrayOfType, isOfType, type OrderType } from '@autone/ui';
import { orderBy } from 'lodash';

import {
  OVERSTOCKED,
  RECEIVING_COVERAGE_AFTER_ALL_TRANSFERS,
  RECEIVING_CURRENT_UNITS,
  SENDING_COVERAGE_AFTER_ALL_TRANSFERS,
  SENDING_CURRENT_UNITS,
  TRANSFER_UNITS,
  UNDERSTOCKED,
} from '../features/edit-rebalancing/location-view/location-sidepanel/constants';
import { type EnrichedLocationDrilldownProposal } from '../types';

export * from './table';

export const isReceivingLocation = (
  data: LocationReceivingSkuProposal | LocationSendingSkuProposal,
): data is LocationReceivingSkuProposal =>
  isOfType<LocationReceivingSkuProposal>(data, 'need_quantity');

export const isReceivingLocationArray = (
  data: LocationSendingSkuProposal[] | LocationReceivingSkuProposal[],
): data is LocationReceivingSkuProposal[] =>
  isArrayOfType<LocationSendingSkuProposal>(data, 'need_quantity');

export const isSendingLocation = (
  data: LocationReceivingSkuProposal | LocationSendingSkuProposal,
): data is LocationSendingSkuProposal =>
  isOfType<LocationSendingSkuProposal>(data, 'excess_quantity');

export const isSendingLocationArray = (
  data: LocationSendingSkuProposal[] | LocationReceivingSkuProposal[],
): data is LocationSendingSkuProposal[] =>
  isArrayOfType<LocationSendingSkuProposal>(data, 'excess_quantity');

type SortColumn = {
  [key: string]: (
    data: EnrichedLocationDrilldownProposal[],
    sortOrder: OrderType,
  ) => EnrichedLocationDrilldownProposal[];
};

const sortFunctions: SortColumn = {
  [SENDING_CURRENT_UNITS]: (data, sortOrder) =>
    orderBy(data, (item) => item.to_location_inventory, [sortOrder]),
  [RECEIVING_CURRENT_UNITS]: (data, sortOrder) =>
    orderBy(data, (item) => item.from_location_inventory, [sortOrder]),
  [UNDERSTOCKED]: (data, sortOrder) =>
    orderBy(data, (item) => item.need_quantity, [sortOrder]),
  [OVERSTOCKED]: (data, sortOrder) =>
    orderBy(data, (item) => item.excess_quantity, [sortOrder]),
  [TRANSFER_UNITS]: (data, sortOrder) =>
    orderBy(data, (item) => item.user_quantity_sort, [sortOrder]),
  [SENDING_COVERAGE_AFTER_ALL_TRANSFERS]: (data, sortOrder) =>
    orderBy(
      data,
      (item) => {
        return item.to_location_all_trips_coverage_sort === 'inf'
          ? Infinity
          : Math.round(Number(item.to_location_all_trips_coverage_sort));
      },
      [sortOrder],
    ),
  [RECEIVING_COVERAGE_AFTER_ALL_TRANSFERS]: (data, sortOrder) =>
    orderBy(
      data,
      (item) => {
        return item.from_location_post_transfer_coverage_sort === 'inf'
          ? Infinity
          : Math.round(Number(item.from_location_post_transfer_coverage_sort));
      },
      [sortOrder],
    ),
};

export const locationDetailSort = (
  data: EnrichedLocationDrilldownProposal[],
  sortColumn: string,
  sortOrder: OrderType,
) => {
  const sortFunction = sortFunctions[sortColumn];
  return sortFunction ? sortFunction(data, sortOrder) : data;
};
