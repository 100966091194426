import { type Filter } from '@autone/openapi-rebalancing';

export const addTripsToFilters = ({
  activeFilters,
  fromLocationId,
  toLocationId,
}: {
  activeFilters: Filter[];
  fromLocationId: string;
  toLocationId: string;
}) =>
  activeFilters
    .filter(
      ({ dimension_id: dimensionId }) =>
        dimensionId !== 'from_location_id' && dimensionId !== 'to_location_id',
    )
    .concat([
      {
        name: 'From Location',
        dimension_title: 'from_location_id',
        dimension_id: 'from_location_id',
        options: [{ id: fromLocationId, title: fromLocationId }],
      },
      {
        name: 'To Location',
        dimension_title: 'to_location_id',
        dimension_id: 'to_location_id',
        options: [{ id: toLocationId, title: toLocationId }],
      },
    ]);
