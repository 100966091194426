import {
  type EditActiveResponse,
  type EditProposalQuantityResponse,
  type Filter,
  type GetProposalProductDrilldownResponse,
  type ProductPage,
  type ProductSendingLocationsSkusPage,
  type ProductStockAnalysisPage,
} from '@autone/openapi-rebalancing';
import {
  encodeUrl,
  type ExtendedCustomBaseQueryType,
  type QueryReturnValue,
} from '@autone/ui';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { EDIT_REBALANCING_TAGS } from '../../../../constant';
import { type EnrichedProductDrilldownProposal } from '../../../../types';
import {
  activeFiltersWithSendingLocation,
  updateProductAggregateCache,
  updateProductDetailCache,
  updateProductDetailDrilldownCache,
} from '../../../helpers/products';
import { handleEditQuantity } from '../../../helpers/shared/handleEditQuantity';
import { handleToggleEnable } from '../../../helpers/shared/handleToggleEnable';
import { rebalancingApi } from '../../rebalancing';

export const productDetailApi = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductSendingLocationSkusPaged: builder.query<
      ProductSendingLocationsSkusPage,
      {
        batchId: string;
        productId: string;
        filters: Filter[];
        includeNilTransfers?: boolean;
        locationSearchTerm?: string;
        sortBy: string;
        cursor: string | null;
        limit: number;
      }
    >({
      providesTags: ['ProductDetailTable'],
      query: ({
        batchId,
        productId,
        filters,
        includeNilTransfers,
        locationSearchTerm,
        sortBy,
        cursor,
        limit,
      }) => ({
        url: encodeUrl({
          url: `/v2/batch/{batchId}/proposal/products/{productId}/sending-locations-skus?${
            cursor ? `cursor={cursor}&` : ''
          }limit={limit}&sort_by={sortBy}`,
          variables: {
            batchId,
            productId,
            cursor: cursor || '',
            limit: `${limit}`,
            sortBy,
          },
        }),
        method: 'POST',
        body: {
          filters,
          include_nil_transfers: includeNilTransfers,
          location_search_term: locationSearchTerm,
        },
      }),
    }),
    getProductDetailDrilldown: builder.query<
      EnrichedProductDrilldownProposal[],
      {
        batchId: string;
        productId: string;
        fromLocationId: string;
        skuId: string;
        filters: Filter[];
      }
    >({
      providesTags: ['ProductDetailDrilldownTable'],
      query: ({ batchId, productId, filters, fromLocationId }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/proposal/products/{productId}`,
          variables: {
            batchId,
            productId,
          },
        }),
        method: 'POST',
        body: {
          filters: activeFiltersWithSendingLocation({
            activeFilters: filters,
            fromLocationId,
          }),
        },
      }),
      transformResponse: (
        response: {
          proposals: EnrichedProductDrilldownProposal[];
        },
        _meta,
        { skuId },
      ) => {
        response.proposals.forEach((item) => {
          item.user_quantity_sort = item.user_quantity;
          item.to_location_all_trips_coverage_sort =
            item.to_location_all_trips_coverage;
          item.to_location_inventory_after_rebalance_sort =
            item.to_location_inventory_after_rebalance;
          item.from_location_inventory_after_rebalance_sort =
            item.from_location_inventory_after_rebalance;
        });
        return response.proposals.filter((d) => d.sku.sku_id === skuId);
      },
    }),
    patchProductSendingLocationSkusStatus: builder.mutation<
      QueryReturnValue,
      {
        batchId: string;
        enabled: boolean;
        productId: string;
        fromLocationId: string;
        skuId: string;
        globalFilters: Filter[];
        tableFilters: Filter[];
        includeNilTransfers: boolean;
        locationSearchTerm: string;
        sortBy: string;
        limit: number;
        aggSearchTerm: string;
        aggLimit: number;
        aggServerSortBy: string;
        aggCursor: string | null;
        isRowExpanded: boolean;
      }
    >({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) =>
          tag !== 'ProductDetailTable' &&
          tag !== 'ProductsPaged' &&
          tag !== 'ProductDetailDrilldownTable',
      ),
      async queryFn(
        {
          batchId,
          enabled,
          productId,
          fromLocationId,
          skuId,
          globalFilters,
          tableFilters,
          includeNilTransfers,
          locationSearchTerm,
          sortBy,
          limit,
          aggSearchTerm,
          aggLimit,
          aggServerSortBy,
          aggCursor,
          isRowExpanded,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<EditActiveResponse>
          | ExtendedCustomBaseQueryType<ProductSendingLocationsSkusPage>
          | ExtendedCustomBaseQueryType<ProductPage>
          | ExtendedCustomBaseQueryType<GetProposalProductDrilldownResponse>,
      ) {
        const { error } = await handleToggleEnable({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditActiveResponse>,
          body: {
            enabled,
            filters: tableFilters,
            from_location_id: fromLocationId,
            sku_id: skuId,
          },
          batchId,
        });

        if (error) {
          return { error } as any;
        }

        await updateProductDetailCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<ProductSendingLocationsSkusPage>,
          dispatch,
          batchId,
          fromLocationId,
          skuId,
          filters: tableFilters,
          productId,
          limit,
          sortBy,
          searchTerm: locationSearchTerm,
          includeNilTransfers,
        });

        await updateProductAggregateCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<ProductPage>,
          productId,
          batchId,
          filters: globalFilters,
          cursor: aggCursor,
          limit: aggLimit,
          searchTerm: aggSearchTerm,
          sortBy: aggServerSortBy,
          dispatch,
        });

        isRowExpanded &&
          (await updateProductDetailDrilldownCache({
            customBaseQuery:
              customBaseQuery as ExtendedCustomBaseQueryType<GetProposalProductDrilldownResponse>,
            dispatch,
            batchId,
            filters: tableFilters,
            skuId,
            selectedProductId: productId,
            fromLocationId,
          }));

        return { data: null, error: null } as any;
      },
    }),
    patchProductDetailDrilldownStatus: builder.mutation<
      {
        data: null;
        error?: FetchBaseQueryError;
      },
      {
        batchId: string;
        toLocationId: string;
        fromLocationId: string;
        selectedProductId: string;
        skuId: string;
        tableFilters: Filter[];
        globalFilters: Filter[];
        enabled: boolean;
        detailTableProps: {
          rowsPerPage: number;
          serverSort: string;
          searchInput: string;
          includeNilTransfers: boolean;
        };
        aggTableProps: {
          rowsPerPage: number;
          serverSort: string;
          searchInput: string;
        };
      }
    >({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) =>
          tag !== 'ProductsPaged' &&
          tag !== 'ProductDetailTable' &&
          tag !== 'ProductDetailDrilldownTable',
      ),
      async queryFn(
        {
          batchId,
          fromLocationId,
          toLocationId,
          selectedProductId,
          skuId,
          tableFilters,
          globalFilters,
          enabled,
          detailTableProps,
          aggTableProps,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<EditActiveResponse>
          | ExtendedCustomBaseQueryType<GetProposalProductDrilldownResponse>
          | ExtendedCustomBaseQueryType<ProductPage>
          | ExtendedCustomBaseQueryType<ProductSendingLocationsSkusPage>,
      ) {
        const { error } = await handleToggleEnable({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditActiveResponse>,
          batchId,
          body: {
            filters: tableFilters,
            enabled,
            from_location_id: fromLocationId,
            to_location_id: toLocationId,
            sku_id: skuId,
          },
        });

        if (error) {
          return { error };
        }

        await updateProductDetailDrilldownCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<GetProposalProductDrilldownResponse>,
          dispatch,
          batchId,
          filters: tableFilters,
          skuId,
          selectedProductId,
          fromLocationId,
          toLocationId,
        });

        const { rowsPerPage, serverSort, searchInput, includeNilTransfers } =
          detailTableProps;

        await updateProductDetailCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<ProductSendingLocationsSkusPage>,
          dispatch,
          batchId,
          fromLocationId,
          skuId,
          filters: tableFilters,
          productId: selectedProductId,
          limit: rowsPerPage,
          sortBy: serverSort,
          searchTerm: searchInput,
          includeNilTransfers,
        });

        const {
          rowsPerPage: aggRowsPerPage,
          serverSort: aggServerSort,
          searchInput: aggTableSearchTerm,
        } = aggTableProps;

        await updateProductAggregateCache({
          productId: selectedProductId,
          batchId,
          filters: globalFilters,
          cursor: null,
          limit: aggRowsPerPage,
          searchTerm: aggTableSearchTerm,
          sortBy: aggServerSort,
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<ProductPage>,
          dispatch,
        });

        return {
          data: null,
        } as any;
      },
    }),
    patchProductDetailDrilldownQuantity: builder.mutation<
      {
        data: null;
        error?: FetchBaseQueryError;
      },
      {
        batchId: string;
        toLocationId: string;
        fromLocationId: string;
        selectedProductId: string;
        skuId: string;
        tableFilters: Filter[];
        globalFilters: Filter[];
        detailTableProps: {
          rowsPerPage: number;
          serverSort: string;
          searchInput: string;
          includeNilTransfers: boolean;
        };
        aggTableProps: {
          rowsPerPage: number;
          serverSort: string;
          searchInput: string;
        };
        quantity: number;
      }
    >({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) =>
          tag !== 'ProductsPaged' &&
          tag !== 'ProductDetailTable' &&
          tag !== 'ProductDetailDrilldownTable',
      ),
      async queryFn(
        {
          batchId,
          fromLocationId,
          toLocationId,
          selectedProductId,
          skuId,
          tableFilters,
          globalFilters,
          detailTableProps,
          aggTableProps,
          quantity,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<EditActiveResponse>
          | ExtendedCustomBaseQueryType<GetProposalProductDrilldownResponse>
          | ExtendedCustomBaseQueryType<ProductPage>
          | ExtendedCustomBaseQueryType<ProductSendingLocationsSkusPage>
          | ExtendedCustomBaseQueryType<EditProposalQuantityResponse>,
      ) {
        const { error } = await handleEditQuantity({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditProposalQuantityResponse>,
          batchId,
          body: {
            quantity,
            from_location_id: fromLocationId,
            to_location_id: toLocationId,
            sku_id: skuId,
          },
        });

        if (error) {
          return { error };
        }

        await updateProductDetailDrilldownCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<GetProposalProductDrilldownResponse>,
          dispatch,
          batchId,
          filters: tableFilters,
          skuId,
          selectedProductId,
          fromLocationId,
          toLocationId,
        });

        const { rowsPerPage, serverSort, searchInput, includeNilTransfers } =
          detailTableProps;

        await updateProductDetailCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<ProductSendingLocationsSkusPage>,
          dispatch,
          batchId,
          fromLocationId,
          filters: tableFilters,
          skuId,
          productId: selectedProductId,
          limit: rowsPerPage,
          sortBy: serverSort,
          searchTerm: searchInput,
          includeNilTransfers,
        });

        const {
          rowsPerPage: aggRowsPerPage,
          serverSort: aggServerSort,
          searchInput: aggTableSearchTerm,
        } = aggTableProps;

        await updateProductAggregateCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<ProductPage>,
          dispatch,
          productId: selectedProductId,
          batchId,
          filters: globalFilters,
          cursor: null,
          limit: aggRowsPerPage,
          searchTerm: aggTableSearchTerm,
          sortBy: aggServerSort,
        });

        return {
          data: null,
        } as any;
      },
    }),
    getProductStockAnalysis: builder.query<
      ProductStockAnalysisPage,
      {
        batchId: string;
        productId: string;
        limit: number;
        cursor: string | null;
        locationFilters: { id: string; description: string }[];
      }
    >({
      query: ({ batchId, productId, cursor, limit, locationFilters }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/proposal/products/{productId}/stock-analysis?${
            cursor ? `cursor={cursor}&` : ''
          }limit={limit}`,
          variables: {
            batchId,
            productId,
            cursor: cursor || '',
            limit: `${limit}`,
          },
        }),
        body: { location_filters: locationFilters },
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetProductSendingLocationSkusPagedQuery,
  useLazyGetProductSendingLocationSkusPagedQuery,
  usePatchProductSendingLocationSkusStatusMutation,
  useGetProductDetailDrilldownQuery,
  usePatchProductDetailDrilldownStatusMutation,
  usePatchProductDetailDrilldownQuantityMutation,
  useGetProductStockAnalysisQuery,
  useLazyGetProductStockAnalysisQuery,
} = productDetailApi;
