import { type User } from '@autone/openapi-core';

import { PRICE_TYPE_NAMES } from '../constants';

export const formatPriceTypeData = (
  availablePriceTypes: User['available_price_types'],
  defaultPriceType: User['default_price_type'],
) => {
  // we map the price types passed into the function and return a title from centralised object
  const priceOptions =
    availablePriceTypes &&
    availablePriceTypes.map(({ name }) => ({
      id: name,
      title:
        (name in PRICE_TYPE_NAMES &&
          PRICE_TYPE_NAMES[name as keyof typeof PRICE_TYPE_NAMES]) ||
        'not defined',
    }));

  return {
    priceOptions,
    defaultPriceOption: priceOptions?.find(
      (item) => item.id === defaultPriceType,
    ),
  };
};
