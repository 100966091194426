import { type Filter } from '@autone/openapi-rebalancing';

export const addProductToFilters = ({
  activeFilters,
  productId,
}: {
  activeFilters: Filter[];
  productId: string;
}) =>
  activeFilters
    .filter(({ dimension_id: dimensionId }) => dimensionId !== 'product_id')
    .concat([
      {
        name: 'Product',
        dimension_title: 'product_id',
        dimension_id: 'product_id',
        options: [{ id: productId, title: productId }],
      },
    ]);
