import {
  type GetDistributionNetworkRequest,
  type GetReturnDistributionNetworkRequestBody,
  type NetworkTrip,
  type TripConfigurationImport,
} from '@autone/openapi-rebalancing';

import { rebalancingApi } from '..';
import { type Trip } from '../../../types/create-rebalancing';
import { formatNetworkDataForApp } from '../../../utils/features/create-rebalancing';

const networkTripsApi = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getNetworkTripData: builder.query<Trip[], GetDistributionNetworkRequest>({
      query: ({
        regions,
        locations,
        location_types: locationTypes,
        countries,
        territories,
        network_level: networkLevel,
        include_distribution_points: sendBackToDistPoint,
        trip_configuration_import_job_id: importJobId,
      }) => {
        return {
          url: `/network`,
          body: {
            regions,
            locations,
            location_types: locationTypes,
            countries,
            territories,
            network_level: networkLevel,
            include_distribution_points: sendBackToDistPoint,
            trip_configuration_import_job_id: importJobId,
          },
          method: 'POST',
        };
      },
      // we destructure response here to avoid having to do it in the component
      transformResponse: (response: { rebalance_network: NetworkTrip[] }) => {
        const networkData = response.rebalance_network;
        /*
        transform data into the format we expect for the logic that is written in useNetworkTripData
        1. the api returns data in this format: {from_location: {}}, to_locations: [{}, {}]}
        2.  we transform it into this format: [{from_location: {}, to_location: {}, active: true}]
        */
        return formatNetworkDataForApp(networkData);
      },
      providesTags: ['NetworkTrips'],
    }),
    getReturnNetworkTripData: builder.query<
      Trip[],
      GetReturnDistributionNetworkRequestBody
    >({
      query: ({
        regions,
        locations,
        location_types: locationTypes,
        countries,
        territories,
        network_level: networkLevel,
        to_locations_limit: toLocationsLimit,
      }) => {
        return {
          url: `/return-network`,
          body: {
            regions,
            locations,
            location_types: locationTypes,
            countries,
            territories,
            network_level: networkLevel,
            to_locations_limit: toLocationsLimit,
          },
          method: 'POST',
        };
      },
      // we destructure response here to avoid having to do it in the component
      transformResponse: (response: { rebalance_network: NetworkTrip[] }) => {
        const networkData = response.rebalance_network;
        /*
        transform data into the format we expect for the logic that is written in useNetworkTripData
        1. the api returns data in this format: {from_location: {}}, to_locations: [{}, {}]}
        2.  we transform it into this format: [{from_location: {}, to_location: {}, active: true}]
        */
        return formatNetworkDataForApp(networkData);
      },
      providesTags: ['NetworkTrips'],
    }),
    getNetworkTripConfigurations: builder.query<
      TripConfigurationImport[],
      undefined
    >({
      query: () => ({
        url: `/network/trip-configurations`,
        method: 'GET',
      }),
      providesTags: ['TripConfigurations'],
      transformResponse: ({
        trip_configurations,
      }: {
        trip_configurations: TripConfigurationImport[];
      }) =>
        trip_configurations.sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
        ),
    }),
  }),
});

export const {
  useGetNetworkTripDataQuery,
  useGetReturnNetworkTripDataQuery,
  useGetNetworkTripConfigurationsQuery,
} = networkTripsApi;
