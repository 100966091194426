import { type NetworkTrip } from '@autone/openapi-rebalancing';

import { type Trip } from '../../../types/create-rebalancing';

type FromResultType = {
  [key: string]: {
    from: { id: string; description: string };
    to: { id: string; description: string }[];
  };
};
type ToResultType = {
  [key: string]: {
    to: { id: string; description: string };
    from: { id: string; description: string }[];
  };
};
// function to format network trip data for the network table
// returns both the fromData and the toData formats

// expects networkTripData to be an array of objects in the format of [{from_location:{id,description},to_location:{id,description},active}]
export const formatNetworkTableData = (networkTripData?: Trip[]) => {
  if (!networkTripData)
    return { formattedFromData: undefined, formattedToData: undefined };
  // ---------------------------------------------------------
  // from data
  // ---------------------------------------------------------
  const formattedFromData = Object.values(
    networkTripData.reduce(
      (
        // result will start as am empty array, and will be populated with the fromLocation and toLocations as we iterate through the networkTripData
        result: FromResultType,
        { from_location: fromLocation, to_location: toLocation },
      ) => {
        // destructure the fromLocation object
        const { id, description } = fromLocation;

        // if the result object does not yet have the fromLocation id, add it to the result object
        if (!result[id]) {
          result[id] = { from: { id, description }, to: [] };
        }
        // if the from location already exists, add the toLocation to the result object
        result[id].to.push({ ...toLocation });

        return result;
      },
      {},
    ),
  );

  // ---------------------------------------------------------
  // to data
  // ---------------------------------------------------------
  const formattedToData = Object.values(
    networkTripData.reduce(
      (
        // result will start as am empty array, and will be populated with the fromLocation and toLocations as we iterate through the networkTripData
        result: ToResultType,
        { to_location: toLocation, from_location: fromLocation },
      ) => {
        // destructure the toLocation object
        const { id, description } = toLocation;
        // if the result object does not yet have the toLocation id, add it to the result object
        if (!result[id]) {
          result[id] = { to: { id, description }, from: [] };
        }
        // if the to location already exists, add the fromLocation to the result object
        result[id].from.push({ ...fromLocation });
        return result;
      },
      {},
    ),
  );
  return { formattedFromData, formattedToData };
};

// ---------------------------------------------------------
// function to format network trip data from the api to use in the app
// formatted as [{from_location: {}, to_location: {}, active: true}]  but expects data in the format of [{from_location: {}, to_locations: [{}, {}]}
// ---------------------------------------------------------
export const formatNetworkDataForApp = (networkTripData?: NetworkTrip[]) => {
  if (!networkTripData) return [];

  // set an empty array to store the transformed data
  const transformedNetworkData: Trip[] = [];

  networkTripData.forEach(
    ({ from_location: fromLocation, to_locations: toLocations }) => {
      // for each to location, push a from_location: {}, to_location: {} object to the transformedNetworkData array
      toLocations.forEach((toLocation) => {
        transformedNetworkData.push({
          from_location: fromLocation,
          to_location: toLocation,
        });
      });
    },
  );

  return transformedNetworkData;
};
// ---------------------------------------------------------
// function to format network trip data for the api
// formatted as [{from_location: {}, to_locations: [{}, {}]}] but expects data in the format of [{from_location: {}, to_location: {}, active: true}]
// ---------------------------------------------------------
// function to format the network trip data for the api
export const formatNetworkDataForApi = (activeNetworkData?: Trip[]) => {
  if (!activeNetworkData) return [];

  const formattedNetworkTripData = activeNetworkData.reduce(
    (result: NetworkTrip[], trip) => {
      const fromLocation = trip.from_location;
      const toLocation = trip.to_location;
      // check if the from location already exists in the results data
      const existingEntry = result.find(
        (item) => item.from_location.id === fromLocation.id,
      );
      // if it exists simply push the to location to the existing entry list
      if (existingEntry) {
        existingEntry.to_locations.push(toLocation);
      } else {
        // if it doesn't exist push new entry into results
        result.push({
          from_location: fromLocation,
          to_locations: [toLocation],
        });
      }

      return result;
    },
    [],
  );
  return formattedNetworkTripData;
};
