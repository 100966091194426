import {
  type EditActiveResponse,
  type Filter,
  type LocationPage,
} from '@autone/openapi-rebalancing';
import {
  encodeUrl,
  type ExtendedCustomBaseQueryType,
  type QueryReturnValue,
} from '@autone/ui';

import { EDIT_REBALANCING_TAGS } from '../../../constant';
import { handleToggleEnable } from '../../../redux/helpers/shared/handleToggleEnable';
import { updateLocationAggregateCache } from '../../helpers/locations';
import { rebalancingApi } from '../rebalancing';

export const locationsApi = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getPagedLocations: builder.query<
      LocationPage,
      {
        batchId: string;
        filters: Filter[];
        locationSearchTerm?: string;
        sortBy: string;
        cursor: string | null;
        limit: number;
      }
    >({
      providesTags: ['LocationsPaged'],
      query: ({
        batchId,
        filters,
        sortBy,
        cursor,
        limit,
        locationSearchTerm,
      }) => ({
        url: encodeUrl({
          url: `v2/batch/{batchId}/proposal/locations?${
            cursor ? `cursor={cursor}&` : ''
          }limit={limit}&sort_by={sortBy}`,
          variables: {
            batchId,
            cursor: cursor || '',
            limit: `${limit}`,
            sortBy,
          },
        }),
        method: 'POST',
        body: { filters, location_search_term: locationSearchTerm },
      }),
    }),
    patchPagedLocationStatus: builder.mutation<
      QueryReturnValue,
      {
        batchId: string;
        locationId: string;
        filters: Filter[];
        enabled: boolean;
        sortBy: string;
        limit: number;
        locationSearchTerm: string;
      }
    >({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) => tag !== 'LocationsPaged',
      ),
      async queryFn(
        {
          batchId,
          locationId,
          filters,
          enabled,
          sortBy,
          limit,
          locationSearchTerm,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<EditActiveResponse>
          | ExtendedCustomBaseQueryType<LocationPage>,
      ) {
        const { error } = await handleToggleEnable({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditActiveResponse>,
          batchId,
          body: {
            filters,
            enabled,
            location_id: locationId,
          },
        });

        if (error) {
          return { error } as any;
        }

        await updateLocationAggregateCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<LocationPage>,
          dispatch,
          batchId,
          filters,
          rowsPerPage: limit,
          serverSort: sortBy,
          locationSearchTerm,
          locationId,
        });

        return { data: null, error: null } as any;
      },
    }),
  }),
});

export const {
  useGetPagedLocationsQuery,
  useLazyGetPagedLocationsQuery,
  usePatchPagedLocationStatusMutation,
} = locationsApi;
