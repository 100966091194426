import { type Filter, type TripPage } from '@autone/openapi-rebalancing';
import {
  encodeUrl,
  errorSnackBar,
  type ExtendedCustomBaseQueryType,
} from '@autone/ui';
import { enqueueSnackbar } from 'notistack';

import { addTripsToFilters } from '../../utils/features';
import { tripsApi } from '../services/edit-rebalancing/tripsPaged';
import { type AppDispatch } from '../store.types';

export * from './locations';

export * from './products';

export const updatedAggregatedTripCache = async ({
  customBaseQuery,
  dispatch,
  batchId,
  filters,
  fromLocationId,
  toLocationId,
  rowsPerPage,
  serverSort,
  aggregateTableSearch,
}: {
  customBaseQuery: ExtendedCustomBaseQueryType<TripPage>;
  dispatch: AppDispatch;
  batchId: string;
  filters: Filter[];
  fromLocationId: string;
  toLocationId: string;
  rowsPerPage: number;
  serverSort: string;
  aggregateTableSearch: string;
}) => {
  const activeFiltersWithTrip = addTripsToFilters({
    activeFilters: filters,
    fromLocationId,
    toLocationId,
  });

  const response = await (
    customBaseQuery as ExtendedCustomBaseQueryType<TripPage>
  )({
    url: encodeUrl({
      url: `v2/batch/{batchId}/proposal/trips?limit={limit}&sort_by={sortBy}`,
      variables: {
        batchId,
        limit: `${rowsPerPage}`,
        sortBy: `${serverSort}`,
      },
    }),
    method: 'POST',
    body: {
      filters: activeFiltersWithTrip,
      location_search_term: aggregateTableSearch,
    },
  });

  if (response.error) {
    return { error: response.error };
  }

  const {
    data: { items: tripsData },
  } = response;

  const foundTrip = tripsData[0];

  if (!foundTrip) {
    errorSnackBar(enqueueSnackbar);
    return { error: 'No trip found' };
  }

  return dispatch(
    tripsApi.util.updateQueryData(
      'getPagedTrips',
      {
        batchId,
        filters,
        sortBy: serverSort,
        cursor: null,
        limit: rowsPerPage,
        locationSearchTerm: aggregateTableSearch,
      },
      (draft) => {
        const trip = draft.items.find(
          (item) =>
            item.from_location.id === fromLocationId &&
            item.to_location.id === toLocationId,
        );

        if (!trip) return;

        trip.enabled = foundTrip.enabled;
        trip.transfer_quantity = foundTrip.transfer_quantity;
        trip.transfer_value = foundTrip.transfer_value;
        trip.unique_products = foundTrip.unique_products;
        trip.sales_uplift = foundTrip.sales_uplift;
      },
    ),
  );
};
