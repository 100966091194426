import {
  type Filter,
  type GrossMarginKPIResponse,
  type OptimalKPI,
  type RebalanceKPIResponse,
  type ReturnToWarehouseKPI,
  type SalesUpliftKPIResponse,
  type SendToHighPerformingStoresKPI,
  type StockOutKPIResponse,
  type UnderstocksKPIResponse,
} from '@autone/openapi-rebalancing';
import { encodeUrl } from '@autone/ui';

import { rebalancingApi } from '../rebalancing';

// Define a service using a base URL and expected endpoints
export const kpisApi = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    postGrossMarginKPI: builder.query<
      GrossMarginKPIResponse,
      { batchId: string; filters: Filter[] }
    >({
      query: ({ batchId, filters }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/kpi/gross-margin`,
          variables: { batchId },
        }),
        method: 'POST',
        body: { filters },
      }),
      providesTags: ['Kpi'],
    }),
    postSalesUpliftKPI: builder.query<
      SalesUpliftKPIResponse,
      { batchId: string; filters: Filter[] }
    >({
      query: ({ batchId, filters }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/kpi/sales-uplift`,
          variables: { batchId },
        }),
        method: 'POST',
        body: { filters },
      }),
      providesTags: ['Kpi'],
    }),
    postStockOutKPI: builder.query<
      StockOutKPIResponse,
      { batchId: string; filters: Filter[] }
    >({
      query: ({ batchId, filters }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/kpi/stock-out`,
          variables: { batchId },
        }),
        method: 'POST',
        body: { filters },
      }),
      providesTags: ['Kpi'],
    }),
    postRebalanceKPI: builder.query<
      RebalanceKPIResponse,
      { batchId: string; filters: Filter[] }
    >({
      query: ({ batchId, filters }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/kpi/rebalance`,
          variables: { batchId },
        }),
        method: 'POST',
        body: { filters },
      }),
      providesTags: ['Kpi'],
    }),
    postUnderstocksKPI: builder.query<
      UnderstocksKPIResponse,
      { batchId: string; filters: Filter[] }
    >({
      query: ({ batchId, filters }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/kpi/understocks`,
          variables: { batchId },
        }),
        method: 'POST',
        body: { filters },
      }),
      providesTags: ['Kpi'],
    }),
    getBatchKpis: builder.query<
      OptimalKPI | SendToHighPerformingStoresKPI | ReturnToWarehouseKPI,
      { batchId: string; filters: Filter[] }
    >({
      query: ({ batchId, filters }) => ({
        url: encodeUrl({
          url: `v2/batch/{batchId}/kpis`,
          variables: { batchId },
        }),
        method: 'POST',
        body: { filters },
      }),
      providesTags: ['Kpi'],
    }),
  }),
});

export const {
  usePostGrossMarginKPIQuery,
  usePostUnderstocksKPIQuery,
  usePostSalesUpliftKPIQuery,
  usePostStockOutKPIQuery,
  usePostRebalanceKPIQuery,
  useGetBatchKpisQuery,
} = kpisApi;
