import {
  type Batch,
  type ProductBestseller,
} from '@autone/openapi-rebalancing';
import { encodeUrl } from '@autone/ui';
import { orderBy } from 'lodash';

import { rebalancingApi } from '../rebalancing';

const bestSellersApis = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getBestsellers: builder.query<
      ProductBestseller[],
      {
        batchId: Batch['id'];
        limit?: number;
        sortBy: 'transfer_quantity' | 'sales_uplift';
      }
    >({
      query: ({ batchId, limit = 5, sortBy }) =>
        encodeUrl({
          url: `batch/{batchId}/bestsellers?limit={limit}&sort_by={sortBy}`,
          variables: { batchId, limit: `${limit}`, sortBy },
        }),
      providesTags: ['Bestsellers'],
      transformResponse: (
        response: { bestsellers: ProductBestseller[] },
        _,
        queryInputs: {
          batchId: Batch['id'];
          limit?: number;
          sortBy: 'transfer_quantity' | 'sales_uplift';
        },
      ) => {
        const { sortBy } = queryInputs;
        const filteredBestsellers = response.bestsellers.filter((item) => {
          const value = item[sortBy];
          return value != null && value > 0;
        });
        return orderBy(filteredBestsellers, (item) => item[sortBy], ['desc']);
      },
    }),
  }),
});

export const { useGetBestsellersQuery } = bestSellersApis;
