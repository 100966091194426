import { type GetScopeProductCountResponse } from '@autone/openapi-rebalancing';

import { rebalancingApi } from '..';
import { type ScopeItemType } from '../../../types/create-rebalancing';

// Define a service using a base URL and expected endpoints
const productScopeCountApi = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductScopeCount: builder.query<
      GetScopeProductCountResponse,
      { scope: ScopeItemType }
    >({
      query: ({ scope }) => ({
        url: `/scope/product-count`,
        method: 'POST',
        body: { scope },
      }),
    }),
  }),
});

export const {
  useGetProductScopeCountQuery,
  useLazyGetProductScopeCountQuery,
} = productScopeCountApi;
