import {
  type Batch,
  type BatchOverviewsPage,
  type BatchSchedule,
  type BatchWithRebalanceNetwork,
  type CreateBatchExportResponse,
  type CreateBatchResponse,
  type CreateBatchScheduleRequest,
  type CreateBatchScheduleResponse,
  type DeleteBatchScheduleResponse,
  type GetBatchExportResponse,
  type GetBatchSubmissionExportResponse,
  type ListBatchOverviewResponse,
  type PostBatchSubmissionResponse,
  type rebalancing__batch__batch__routes__CreateBatchRequest,
  type ValidateBatchScopeRequest,
  type ValidateBatchScopeResponse,
} from '@autone/openapi-rebalancing';
import { type CommonTranslationFunction } from '@autone/translations';
import { encodeUrl } from '@autone/ui';
import { enqueueSnackbar } from 'notistack';

import { rebalancingApi } from '..';

const batchesApis = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getBatch: builder.query<
      BatchWithRebalanceNetwork,
      { batchId: string; extraArg?: string }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({ url: `/batch/{batchId}`, variables: { batchId } }),
      }),
      providesTags: ['Batch'],
      // we destructure response.batches here to avoid having to do it in the component
      transformResponse: (response: { batch: BatchWithRebalanceNetwork }) =>
        response.batch,
    }),
    getBatches: builder.query<ListBatchOverviewResponse, void>({
      query: () => `batch/overview`,
      providesTags: ['Batches'],
    }),
    patchBatch: builder.mutation<
      Batch,
      {
        batchId: Batch['id'];
        status?: Batch['status'];
        name?: Batch['name'];
        launchNewRebalOverview?: boolean;
      }
    >({
      invalidatesTags: ['Batch', 'BatchOverviewsPaged'],
      query: ({ batchId, status, name }) => {
        return {
          url: encodeUrl({ url: `/batch/{batchId}`, variables: { batchId } }),
          method: 'PATCH',
          body: {
            batch: {
              status,
              name,
            },
          },
        };
      },
      async onQueryStarted(
        { batchId, status, name, launchNewRebalOverview },
        { dispatch, queryFulfilled },
      ) {
        let patchResult;

        if (!launchNewRebalOverview) {
          //TODO: remove once we have migrated fully to new version
          patchResult = dispatch(
            batchesApis.util.updateQueryData(
              'getBatches',
              undefined,
              (draft) => {
                draft.batches.forEach((item) => {
                  if (item.batch.id === batchId) {
                    if (status) {
                      item.batch.status = status;
                    }
                    if (name) {
                      item.batch.name = name;
                    }
                  }
                });
              },
            ),
          );
        }
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
      transformResponse: (response: { batch: Batch }) => response.batch,
    }),
    createBatch: builder.mutation<
      CreateBatchResponse,
      rebalancing__batch__batch__routes__CreateBatchRequest
    >({
      query(body) {
        return {
          url: '/batch',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['NetworkTrips'],
    }),
    createBatchSchedule: builder.mutation<
      CreateBatchScheduleResponse,
      CreateBatchScheduleRequest
    >({
      invalidatesTags: ['Batches'],
      query: (body) => ({
        url: `/batch/schedule`,
        method: 'POST',
        body,
        extraArgs: { hideSnackbar: true },
      }),
    }),
    submitBatch: builder.mutation<
      PostBatchSubmissionResponse,
      { batchId: string; recipientIds: string[] | undefined }
    >({
      query: ({ batchId, recipientIds }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/submission`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {
          recipient_ids: recipientIds,
        },
      }),
      invalidatesTags: ['Batches', 'Batch'],
    }),
    deleteBatch: builder.mutation<
      void,
      {
        batchId: string;
        submissionId: string;
        recipientIds: string[] | undefined;
      }
    >({
      query: ({ batchId, submissionId, recipientIds }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/submission/{submissionId}`,
          variables: { batchId, submissionId },
        }),
        method: 'DELETE',
        body: {
          recipient_ids: recipientIds,
        },
      }),
      invalidatesTags: ['Batches', 'Batch'],
    }),
    deleteSchedule: builder.mutation<
      DeleteBatchScheduleResponse,
      { scheduleId: string }
    >({
      invalidatesTags: ['Batch', 'Batches'],
      query: ({ scheduleId }) => ({
        url: encodeUrl({
          url: `batch/schedule/{scheduleId}`,
          variables: { scheduleId },
        }),
        method: 'DELETE',
      }),
    }),
    getBatchOverviewsPaged: builder.query<
      BatchOverviewsPage,
      {
        batchStatuses: Batch['status'][];
        sortBy: string | null;
        limit: number;
        cursor: string | null;
        searchTerm: string | null;
        type?: string;
      }
    >({
      providesTags: ['Batches', 'BatchOverviewsPaged'],
      query: ({ batchStatuses, sortBy, limit, cursor, searchTerm }) => {
        const batchStatusesQueryString = batchStatuses
          .map((status) => `batch_statuses=${status}`)
          .join('&');

        return {
          url: encodeUrl({
            url: `/v2/batch/overview?${batchStatusesQueryString}&limit={limit}${
              sortBy ? '&sort_by={sortBy}' : ''
            }${cursor ? `&cursor={cursor}` : ''}${
              searchTerm ? `&batch_name_search_term={searchTerm}` : ''
            }`,
            variables: {
              limit: limit.toString(),
              sortBy: sortBy || '',
              searchTerm: searchTerm || '',
              cursor: cursor || '',
            },
          }),
          method: 'POST',
        };
      },
    }),
    getScheduledBatches: builder.query<BatchSchedule[], void>({
      providesTags: ['Batches'],
      query: () => ({
        url: '/batch/overview/scheduled',
      }),
      transformResponse: ({ items }: { items: BatchSchedule[] }) => items,
    }),
    createBatchExport: builder.query<
      CreateBatchExportResponse,
      { batchId: string }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: `/v2/batch/{batchId}/export`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {},
      }),
    }),
    getBatchExport: builder.query<
      GetBatchExportResponse,
      { batchId: string; exportId: string }
    >({
      query: ({ batchId, exportId }) => ({
        url: encodeUrl({
          url: `/v2/batch/{batchId}/export/{exportId}`,
          variables: { batchId, exportId },
        }),
        method: 'GET',
      }),
    }),
    archiveBatch: builder.mutation<
      null,
      {
        batchId: string;
        common: CommonTranslationFunction;
      }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: '/batch/{batchId}/archive',
          variables: { batchId },
        }),
        method: 'POST',
      }),
      invalidatesTags: ['Batches', 'BatchOverviewsPaged'],
      async onQueryStarted({ common }, { queryFulfilled }) {
        try {
          await queryFulfilled;
          enqueueSnackbar(common('archive-batches.snackbar-text'), {
            variant: 'success',
          });
        } catch (err) {
          return err as any;
        }
      },
    }),
    getSubmissionExport: builder.query<
      GetBatchSubmissionExportResponse,
      { batchId: string }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/submission/export`,
          variables: { batchId },
        }),
        method: 'GET',
        extraArgs: { hideSnackbar: true },
      }),
    }),
    validateBatch: builder.mutation<
      ValidateBatchScopeResponse,
      ValidateBatchScopeRequest
    >({
      query: (body) => ({
        url: '/batch/validate',
        method: 'POST',
        body,
        extraArgs: { hideSnackbar: true },
      }),
    }),
  }),
});

export const {
  useDeleteBatchMutation,
  useSubmitBatchMutation,
  useCreateBatchMutation,
  useCreateBatchScheduleMutation,
  useGetBatchQuery,
  useLazyGetBatchQuery,
  useGetBatchesQuery,
  usePatchBatchMutation,
  useDeleteScheduleMutation,
  useGetBatchOverviewsPagedQuery,
  useGetScheduledBatchesQuery,
  useLazyCreateBatchExportQuery,
  useGetBatchExportQuery,
  useArchiveBatchMutation,
  useLazyGetSubmissionExportQuery,
  useValidateBatchMutation,
} = batchesApis;
